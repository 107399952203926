/* Category selector styles */
.category-selector-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
}

.selection-summary {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

body.dark-mode .selection-summary {
    background-color: #27292D;
}

.selected-list {
    list-style-type: none;
    padding-left: 0;
    max-height: 200px;
    overflow-y: auto;
}

.selected-list li {
    padding: 5px 0;
    border-bottom: 1px solid #eee;
}

body.dark-mode .selected-list li {
    border-bottom-color: #444;
}

.category-tree-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    max-height: 475px;
    overflow-y: auto;
}

body.dark-mode .category-tree-container {
    border-color: #444;
}

.category-list {
    list-style-type: none;
    padding-left: 0;
}

.category-list ul {
    padding-left: 20px;
}

.category-item {
    margin: 5px 0;
}

.category-header {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
}

.category-header:hover {
    background-color: #f0f0f0;
}

body.dark-mode .category-header:hover {
    background-color: #3a3a3a;
}

.toggle-icon {
    margin-right: 8px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    padding-left: 2px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    /* background-color: blue; */
}

/* .toggle-icon.expanded {
    transform: rotate(90deg);
} */

.category-label {
    display: flex;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
}

.category-name {
    margin-left: 8px;
    flex-grow: 1;
}

.category-size {
    font-size: 0.85em;
    color: #666;
    margin-left: 15px;
}

body.dark-mode .category-size {
    color: #aaa;
}

/* Selected category styling */
.category-header.selected {
    background-color: #e6f7ff;
}

body.dark-mode .category-header.selected {
    background-color: #1a3f5c;
}

/* Checkbox styling */
.category-label input[type="checkbox"] {
    margin-right: 5px;
}

.category-name.clickable {
    cursor: pointer;
    text-decoration: underline;
}

.category-name.clickable:hover {
    color: #0066cc;
}

body.dark-mode .category-name.clickable:hover {
    color: #4d94ff;
}

.select-all-link {
    color: #0066cc;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.85em;
    margin-left: 8px;
}

.select-all-link:hover {
    color: #004499;
}

body.dark-mode .select-all-link {
    color: #4d94ff;
}

body.dark-mode .select-all-link:hover {
    color: #80b3ff;
}

.category-search-container {
    margin-bottom: 15px;
}

.search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.search-icon {
    position: absolute;
    left: 10px;
    color: #666;
}

.category-search-input {
    width: 100%;
    padding: 8px 10px 8px 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.2s;
}

.category-search-input:focus {
    outline: none;
    border-color: #5C4BD3;
    box-shadow: 0 0 0 2px rgba(92, 75, 211, 0.2);
}

.clear-search-button {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    color: #666;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.clear-search-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.no-results-message {
    color: #666;
    font-style: italic;
    padding: 10px 0;
    text-align: center;
}

/* Dark mode styles */
.dark-mode .category-search-input {
    background-color: #2d2d2d;
    border-color: #444;
    color: #e0e0e0;
}

.dark-mode .search-icon,
.dark-mode .clear-search-button {
    color: #aaa;
}

.dark-mode .category-search-input:focus {
    border-color: #7C6BE3;
    box-shadow: 0 0 0 2px rgba(124, 107, 227, 0.2);
}

.dark-mode .clear-search-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .no-results-message {
    color: #aaa;
}