.meta-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
}

.meta-modal {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    animation: slideUp 0.3s ease-out;
}

.meta-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #eaeaea;
}

.meta-modal-header h3 {
    margin: 0;
    color: #1877F2; /* Meta blue */
    font-size: 1.3rem;
}

.meta-modal-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    padding: 0;
    line-height: 1;
}

.meta-modal-body {
    padding: 20px;
    flex-grow: 1;
}

.meta-modal-body p {
    margin-bottom: 15px;
    line-height: 1.5;
}

.meta-modal-image {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

.meta-modal-steps {
    background-color: #f7f7f7;
    padding: 15px;
    border-radius: 6px;
    margin-top: 15px;
}

.meta-modal-steps ol {
    margin-top: 10px;
    padding-left: 25px;
}

.meta-modal-steps li {
    margin-bottom: 8px;
}

.meta-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 20px;
    border-top: 1px solid #eaeaea;
    gap: 12px;
}

.meta-modal-primary {
    background-color: #1877F2; /* Meta blue */
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.meta-modal-primary:hover {
    background-color: #166FE5;
}

.meta-modal-secondary {
    background-color: #e4e6eb;
    color: #050505;
    border: none;
    padding: 10px 16px;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.meta-modal-secondary:hover {
    background-color: #d8dadf;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}