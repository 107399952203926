.video-generation-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  transition: all 0.3s ease;
  border: 1px solid #eee;
}

.dark-mode .video-generation-card {
  background-color: #2d2d2d;
  border-color: #444;
}

.video-generation-card.generating {
  border-color: #5C4BD3;
  box-shadow: 0 0 0 1px rgba(92, 75, 211, 0.5);
}

.video-generation-card.error {
  border-color: #f44336;
  box-shadow: 0 0 0 1px rgba(244, 67, 54, 0.5);
}

.video-generation-card.completed {
  border-color: #4caf50;
  box-shadow: 0 0 0 1px rgba(76, 175, 80, 0.5);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.card-header h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.card-actions {
  display: flex;
  gap: 8px;
}

.card-action-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.card-action-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark-mode .card-action-button {
  color: #aaa;
}

.dark-mode .card-action-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.card-interest {
  margin-bottom: 12px;
}

.card-interest h5 {
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.dark-mode .card-interest h5 {
  color: #aaa;
}

.interest-tag {
  display: inline-block;
  background-color: #e9ecef;
  color: #495057;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
}

.dark-mode .interest-tag {
  background-color: #444;
  color: #ddd;
}

.card-prompt {
  margin-bottom: 16px;
}

.card-prompt p {
  margin: 0;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #eee;
}

.dark-mode .card-prompt p {
  color: #ddd;
  background-color: #333;
  border-color: #444;
}

.card-prompt textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  resize: vertical;
  min-height: 80px;
  background-color: #f9f9f9;
}

.dark-mode .card-prompt textarea {
  background-color: #333;
  border-color: #444;
  color: #ddd;
}

.card-prompt textarea:focus {
  outline: none;
  border-color: #5C4BD3;
  box-shadow: 0 0 0 2px rgba(92, 75, 211, 0.2);
}

.dark-mode .card-prompt textarea:focus {
  border-color: #7C6BE3;
  box-shadow: 0 0 0 2px rgba(124, 107, 227, 0.2);
}

.card-video {
  margin-bottom: 16px;
}

.generated-video {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.video-actions {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.video-action-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.video-action-button.download {
  background-color: #4caf50;
  color: white;
}

.video-action-button.download:hover {
  background-color: #43a047;
}

.video-action-button.regenerate {
  background-color: #5C4BD3;
  color: white;
}

.video-action-button.regenerate:hover:not(:disabled) {
  background-color: #4a3db0;
}

.video-action-button.regenerate:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dark-mode .video-action-button.regenerate:disabled {
  background-color: #555;
}

.card-generating {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  background-color: rgba(92, 75, 211, 0.05);
  border-radius: 6px;
  margin-bottom: 16px;
}

.generating-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.spinner {
  width: 30px;
  height: 30px;
  border: 3px solid rgba(92, 75, 211, 0.3);
  border-radius: 50%;
  border-top-color: #5C4BD3;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.generating-indicator p {
  margin: 0;
  color: #5C4BD3;
  font-size: 14px;
}

.dark-mode .generating-indicator p {
  color: #7C6BE3;
}

.card-error {
  background-color: rgba(244, 67, 54, 0.1);
  border: 1px solid rgba(244, 67, 54, 0.3);
  color: #f44336;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 13px;
}

.dark-mode .card-error {
  background-color: rgba(244, 67, 54, 0.15);
  border-color: rgba(244, 67, 54, 0.4);
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.credit-cost {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 13px;
}

.dark-mode .credit-cost {
  color: #aaa;
}

.generate-button {
  background-color: #5C4BD3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.generate-button:hover:not(:disabled) {
  background-color: #4a3db0;
}

.generate-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dark-mode .generate-button:disabled {
  background-color: #555;
}

.spin {
  animation: spin 1s linear infinite;
}