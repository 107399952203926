.layout-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    transition: background-color 0.3s ease;
}
  
.sidebar {
    width: 255px;
    background: #27292D;
    color: white;
    transition: width 0.3s ease, background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
}
  
.sidebar.collapsed {
    width: 60px;
    min-width: 60px;
}

.sidebar.collapsed .sidebar-footer {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.sidebar.collapsed .dark-mode-toggle {
    justify-content: center;
    padding: 10px 0;
    width: 60px;
    align-self: flex-start;
}
  
.sidebar-toggle {
    position: absolute;
    right: -12px;
    top: 20px;
    background: #27292D;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 2;
}
  
.sidebar-header {
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.sidebar-nav {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}

.nav-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
  
.nav-item {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    transition: all 0.2s ease;
    position: relative;
    font-size: 14px;
}

.nav-item span {
    margin-left: 12px;
    white-space: nowrap;
    transition: opacity 0.2s ease, margin-left 0.2s ease;
}
  
.nav-item:hover {
    background: rgba(255, 255, 255, 0.1);
    color: white;
}
  
.nav-item.active {
    background: rgba(255, 255, 255, 0.15);
    color: white;
    border-left: 3px solid #AB5898;
}
  
.nav-item span {
    margin-left: 12px;
    white-space: nowrap;
}
  
.main-content {
    flex: 1;
    overflow-y: auto;
    transition: margin-left 0.3s ease;
    background: #f5f5f5;
    width: calc(100% - 255px); /* Width when sidebar is open */
}
  
.main-content.expanded {
    width: calc(100% - 60px); /* Width when sidebar is collapsed */
    margin-left: 0; /* Remove any margin that might be causing the issue */
}
  
.coming-soon-badge {
    position: absolute;
    right: 15px;
    background: #ff6b6b;
    color: white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 10px;
    font-weight: bold;
}

.sidebar-footer {
    margin-top: auto;
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
}
  
.dark-mode-toggle {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    transition: background-color 0.2s ease;
    border-radius: 4px;
}
  
.dark-mode-toggle:hover {
    background: rgba(255, 255, 255, 0.1);
    color: white;
}
  
.dark-mode-toggle span {
    margin-left: 12px;
    white-space: nowrap;
}
  
/* Dark mode styles */
.dark-mode .main-content {
    background-color: #1a1a1a;
    color: #e0e0e0;
}
  
.dark-mode .sidebar {
    background-color: #27292D;
}
  
.dark-mode .sidebar-toggle {
    background-color: #27292D;
}

.add-credits-button {
    display: block;
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
.add-credits-button:hover {
    background-color: #45a049;
}

.add-credits-button:active {
    background-color: #3e8e41;
}