/* Light mode (default) */
:root {
    --bg-color: #ffffff;
    --text-color: #333333;
    --input-bg: #f5f5f5;
    --input-border: #dddddd;
    --button-bg: #4a90e2;
    --button-text: #ffffff;
  }
  
  /* Dark mode */
  body.dark-mode {
    --bg-color: #1a1a1a;
    --text-color: #ffffff;
    --input-bg: #27292D;
    --input-border: #404040;
    --button-bg: #2d5a9e;
    --button-text: #ffffff;
  }
  
  .workspace {
    background-color: var(--bg-color);
    color: var(--text-color);
    min-height: 100vh;
    padding: 20px;
    transition: all 0.3s ease;
  }
  
  
.theme-toggle {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    align-items: center;
    gap: 10px;
  }
  
  .toggle-label {
    font-size: 16px;
  }
  
  /* Toggle Switch styles */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--button-bg);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--button-bg);
  }
  
  input:checked + .slider:before {
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  



  .input-container {
    max-width: 600px;
    margin: 0 auto;
    width: 90%;
    padding: 0 15px;
  }
  
  .input-container label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .input-container input {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 12px;
    font-size: 16px;
    border: 2px solid var(--input-border);
    border-radius: 5px;
    background-color: var(--input-bg);
    color: var(--text-color);
    transition: all 0.3s ease;
    margin-bottom: 10px;
  }
  
  .input-container input:focus {
    outline: none;
    border-color: var(--button-bg);
  }
  
  .input-container input::placeholder {
    color: #888;
  }

  /* Video prompt styling */
.video-prompt-container {
  margin-bottom: 24px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

body.dark-mode .video-prompt-container {
  background-color: #27292D;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.video-prompt-container label {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

body.dark-mode .video-prompt-container label {
  color: #e0e0e0;
}

.textarea-wrapper {
  position: relative;
}

.video-prompt-container textarea {
  width: 100%;
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 15px;
  line-height: 1.5;
  transition: border-color 0.3s, box-shadow 0.3s;
  resize: vertical;
  min-height: 80px;
  box-sizing: border-box;
}

.video-prompt-container textarea:focus {
  border-color: #4d94ff;
  box-shadow: 0 0 0 2px rgba(77, 148, 255, 0.2);
  outline: none;
}

body.dark-mode .video-prompt-container textarea {
  background-color: #3d3d3d;
  border-color: #555;
  color: #e0e0e0;
}

body.dark-mode .video-prompt-container textarea:focus {
  border-color: #4d94ff;
  box-shadow: 0 0 0 2px rgba(77, 148, 255, 0.3);
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.clear-button:hover {
  background-color: #eee;
  color: #555;
}

body.dark-mode .clear-button {
  color: #aaa;
}

body.dark-mode .clear-button:hover {
  background-color: #555;
  color: #eee;
}

.prompt-examples {
  margin-top: 12px;
}

.prompt-examples p {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

body.dark-mode .prompt-examples p {
  color: #aaa;
}

.example-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.example-chips button {
  background-color: #e9ecef;
  border: none;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 13px;
  color: #495057;
  cursor: pointer;
  transition: all 0.2s;
}

.example-chips button:hover {
  background-color: #dee2e6;
}

body.dark-mode .example-chips button {
  background-color: #444;
  color: #ddd;
}

body.dark-mode .example-chips button:hover {
  background-color: #555;
}

/* Interest section styling */
.interests-section {
  margin: 30px 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

body.dark-mode .interests-section {
  background-color: #27292D;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.interests-description {
  margin-bottom: 20px;
  color: #666;
  font-size: 15px;
  line-height: 1.5;
}

body.dark-mode .interests-description {
  color: #aaa;
}

.selected-interests-summary {
  margin-top: 20px;
  padding: 15px;
  background-color: #e9f7fe;
  border-radius: 8px;
  border-left: 4px solid #4d94ff;
}

body.dark-mode .selected-interests-summary {
  background-color: #1a3f5c;
  border-left-color: #4d94ff;
}

.interest-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
}

.interest-tag {
  background-color: #4d94ff;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
}

body.dark-mode .interest-tag {
  background-color: #3a7cc7;
}

/* Progress bar styling */
.generation-progress {
  margin: 20px 0;
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

body.dark-mode .progress-bar-container {
  background-color: #444;
}

.progress-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #4d94ff, #6a5acd);
  border-radius: 4px;
  transition: width 0.5s ease;
}

.progress-text {
  margin-top: 8px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

body.dark-mode .progress-text {
  color: #aaa;
}

.result-container {
  margin-top: 30px;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 8px;
}

body.dark-mode .result-container {
  background-color: #333;
}

.download-link {
  display: inline-block;
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.download-link:hover {
  background-color: #40a9ff;
}

body.dark-mode .download-link {
  background-color: #177ddc;
}

body.dark-mode .download-link:hover {
  background-color: #40a9ff;
}

.video-generation-container {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

body.dark-mode .video-generation-container {
  background-color: #27292D;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.file-name {
  margin-top: 8px;
  font-size: 14px;
  color: #666;
}

body.dark-mode .file-name {
  color: #aaa;
}

.selected-file-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 8px 12px;
  margin-top: 5px;
}

.dark-mode .selected-file-container {
  background-color: rgba(255, 255, 255, 0.1);
}

.selected-file-info {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
}

.file-icon {
  font-size: 18px;
}

.file-name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;
}

.file-actions {
  display: flex;
  gap: 8px;
}

.file-action-button {
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.file-action-button.replace {
  color: #4285f4;
}

.file-action-button.clear {
  color: #ea4335;
}

.file-action-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark-mode .file-action-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.message-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  border-radius: 6px;
  margin: 15px 0;
  font-size: 14px;
}

.message-container.error {
  background-color: rgba(244, 67, 54, 0.1);
  border: 1px solid rgba(244, 67, 54, 0.3);
  color: #f44336;
}

.message-container.success {
  background-color: rgba(76, 175, 80, 0.1);
  border: 1px solid rgba(76, 175, 80, 0.3);
  color: #4caf50;
}

.message-container.info {
  background-color: rgba(33, 150, 243, 0.1);
  border: 1px solid rgba(33, 150, 243, 0.3);
  color: #2196f3;
}

.message-container .message {
  margin: 0;
}

/* Dark mode styles */
.dark-mode .message-container.error {
  background-color: rgba(244, 67, 54, 0.15);
  border-color: rgba(244, 67, 54, 0.4);
}

.dark-mode .message-container.success {
  background-color: rgba(76, 175, 80, 0.15);
  border-color: rgba(76, 175, 80, 0.4);
}

.dark-mode .message-container.info {
  background-color: rgba(33, 150, 243, 0.15);
  border-color: rgba(33, 150, 243, 0.4);
}

.interests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-top: 16px;
}

.interest-item {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interest-item:hover {
  border-color: #5C4BD3;
  background-color: #f0f0f5;
}

.interest-item.selected {
  background-color: rgba(92, 75, 211, 0.1);
  border-color: #5C4BD3;
}

.interest-name {
  font-size: 14px;
  font-weight: 500;
}

.interest-selected-indicator {
  color: #5C4BD3;
  font-weight: bold;
}

.dark-mode .interest-item {
  background-color: #333;
  border-color: #444;
}

.dark-mode .interest-item:hover {
  border-color: #7C6BE3;
  background-color: #3a3a3a;
}

.dark-mode .interest-item.selected {
  background-color: rgba(124, 107, 227, 0.2);
  border-color: #7C6BE3;
}

.dark-mode .interest-selected-indicator {
  color: #7C6BE3;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.credit-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  font-size: 14px;
  color: #666;
}

.dark-mode .credit-info {
  color: #aaa;
}

.video-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.generate-all-button {
  background-color: #5C4BD3;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  display: block;
  margin: 0 auto;
}

.generate-all-button:hover:not(:disabled) {
  background-color: #4a3db0;
}

.generate-all-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dark-mode .generate-all-button:disabled {
  background-color: #555;
}

.analyzing-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
}

.analyzing-indicator .spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(92, 75, 211, 0.3);
  border-radius: 50%;
  border-top-color: #5C4BD3;
  animation: spin 1s linear infinite;
}

.analyzing-indicator p {
  color: #5C4BD3;
  font-size: 16px;
  font-weight: 500;
}

.dark-mode .analyzing-indicator p {
  color: #7C6BE3;
}

.no-interests-selected {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  color: #666;
}

.dark-mode .no-interests-selected {
  background-color: #333;
  color: #aaa;
}

@media (max-width: 768px) {
  .video-cards-container {
    grid-template-columns: 1fr;
  }
  
  .interests-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}