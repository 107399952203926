.credit-display {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    margin: 0 10px 10px;
    transition: all 0.3s ease;
    height: 20px;
}

.credit-display.collapsed {
    justify-content: center;
    padding: 10px;
    margin: 0 5px 10px;
    width: 40px;
    height: 24px;
    margin-left: 0;
}

.credit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    margin-right: 10px;
    flex-shrink: 0;
}

.credit-display.collapsed .credit-icon {
    margin-right: 4px;
    height: 12px;
    width: 12px;
}

.credit-info {
    flex: 1;
    min-width: 0;
    height: 100%;
}

.credit-text {
    display: flex;
    align-items: baseline;
    margin-bottom: 4px;
    font-size: 14px;
}

.credit-remaining {
    font-weight: 600;
    color: white;
}

.credit-total {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    margin-left: 2px;
    overflow: hidden;
    white-space: nowrap;
}

.credit-progress-container {
    position: relative;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    overflow: hidden;
}

.credit-progress-bar {
    height: 100%;
    border-radius: 2px;
    transition: width 0.3s ease, background-color 0.3s ease;
}

.credit-percentage {
    position: absolute;
    top: 6px;
    right: 0;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
}

.credit-loading {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
}

.credit-mini {
    display: flex;
    align-items: center;
    justify-content: center;
}

.credit-mini-text {
    font-size: 10px;
    font-weight: 600;
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 2px 5px;
}

/* Dark mode specific styles */
.dark-mode .credit-display {
    background-color: rgba(0, 0, 0, 0.2);
}

.dark-mode .credit-icon {
    background-color: rgba(255, 255, 255, 0.05);
}