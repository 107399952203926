/* Global dark mode styles */
body.dark-mode {
    background-color: #1a1a1a;
    color: #e0e0e0;
}

/* Card and container styles */
.dark-mode .card,
.dark-mode .container,
.dark-mode .result-container,
.dark-mode .form-container {
    background-color: #1e1e1e;
    color: #e0e0e0;
    border-color: #333;
}

/* Input styles */
.dark-mode input,
.dark-mode textarea,
.dark-mode select {
    background-color: #2d2d2d;
    color: #e0e0e0;
    border-color: #444;
}

.dark-mode input::placeholder,
.dark-mode textarea::placeholder {
    color: #aaa;
}

/* Button styles */
.dark-mode button:not(.gradient-button):not(.dark-mode-toggle):not(.main-button):not(.feedback-button) {
    background-color: #333;
    color: #e0e0e0;
    border-color: #555;
}

.dark-mode button:not(.gradient-button):not(.dark-mode-toggle):not(.main-button):not(.feedback-button):hover {
    background-color: #444;
}

/* Heading styles */
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {
    color: #fff;
}

/* Link styles */
/* .dark-mode a {
    color: #90caf9;
} */

/* .dark-mode a:hover {
    color: #bbdefb;
} */

/* Modal styles */
.dark-mode .meta-modal {
    background-color: #1e1e1e;
    color: #e0e0e0;
}

.dark-mode .meta-modal-header {
    border-bottom-color: #333;
}

.dark-mode .meta-modal-footer {
    border-top-color: #333;
}

.dark-mode .meta-modal-steps {
    background-color: #2d2d2d;
}

.dark-mode .meta-modal-secondary {
    background-color: #333;
    color: #e0e0e0;
}

.dark-mode .meta-modal-secondary:hover {
    background-color: #444;
}

/* Content Calendar specific styles */
.dark-mode .content-calendar-container ul {
    color: #bbb;
}

.dark-mode .content-calendar-container div[style*="background: #f9f9f9"] {
    background-color: #2d2d2d !important;
}

/* Settings specific styles */
.dark-mode .settings-container div[style*="background: white"] {
    background-color: #1e1e1e !important;
}

.dark-mode .settings-container p[style*="color: #666"] {
    color: #bbb !important;
}